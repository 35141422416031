
            <!--begin::Aside-->
            <div class="d-flex flex-center flex-lg-start flex-column">
                <!--begin::Logo-->
                <a class="mb-7">
                    <img alt="Logo" src="assets/media/foodenza/logo-3.png"/>
                </a>
                <!--end::Logo-->
                <!--begin::Title-->
                <!-- <h2 class="text-white fw-normal m-0">{{ 'authentication.logoDescription' | translate }}</h2> -->
                <!--end::Title-->
            </div>
