<div class="d-flex flex-column flex-root" id="kt_app_root">
    <!--begin::Authentication - Password reset -->
    <div class="d-flex flex-column flex-column-fluid flex-lg-row">
                <!--begin::Aside-->
                <div class="d-flex flex-center w-lg-50 pt-15 pt-lg-0 px-10">
                    <!--begin::Aside-->
                    <app-authentication-layout-aside></app-authentication-layout-aside>
                    <!--begin::Aside-->
                </div>
        <!--begin::Aside-->
        <router-outlet></router-outlet>
    </div>
    <!--end::Authentication - Password reset-->
</div>
<!--end::Root-->