import { Component } from '@angular/core';

@Component({
  selector: 'app-authentication-layout-aside',
  templateUrl: './authentication-layout-aside.component.html',
  styleUrl: './authentication-layout-aside.component.css'
})
export class AuthenticationLayoutAsideComponent {

}
