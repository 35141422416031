import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { SettingsService } from '../../../shared/services/settings.service';
import { Theme } from '../../../shared/models/enums';

@Component({
  selector: 'app-authentication-layout',
  templateUrl: './authentication-layout.component.html',
  styleUrl: './authentication-layout.component.css'
})
export class AuthenticationLayoutComponent implements OnInit, OnDestroy {

  private bodyClasses = ['app-blank', 'bgi-size-cover', 'bgi-attachment-fixed', 'bgi-position-center', 'bgi-no-repeat']

  constructor(private renderer: Renderer2, @Inject(DOCUMENT) private document: Document, private settingsService: SettingsService) {

  }
  ngOnDestroy(): void {
    this.bodyClasses.forEach((bodyClass) => this.renderer.removeClass(this.document.body, bodyClass))

    this.renderer.removeStyle(this.document.body, 'background-image')
  }

  ngOnInit(): void {
    this.bodyClasses.forEach((bodyClass) => this.renderer.addClass(this.document.body, bodyClass))

    this.renderer.setAttribute(this.document.body, 'id', 'kt_body')

    this.settingsService.theme$.subscribe((theme) => {
      if (theme === Theme.DARK) {
        this.renderer.setStyle(this.document.body, 'background-image', "url('/assets/media/auth/auth-bg.jpg')")
        return
      }

      this.renderer.setStyle(this.document.body, 'background-image', "url('/assets/media/auth/auth-bg.jpg')")
    })
  }

}
